<template>
  <div>
    <div id="request-form-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" v-if="dialogVisible">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2
              class="font-medium text-base"
              :class="isRTL ? 'ml-auto' : 'mr-auto'"
            >
              <app-i18n
                code="request.modals.acceptRequest"
                v-if="action == 'accept'"
              ></app-i18n>
              <app-i18n
                code="request.modals.rejectRequest"
                v-if="action == 'reject'"
              ></app-i18n>
              <app-i18n
                code="request.modals.closeRequest"
                v-if="action == 'close'"
              ></app-i18n>
            </h2>
          </div>
          <!-- END: Modal Header -->

          <!-- BEGIN: Modal Body -->
          <app-request-accept-form
            :type="type"
            :id="id"
            @close="doCloseModal"
            v-if="action == 'accept'"
          />
          <app-request-reject-form
            :type="type"
            v-if="action == 'reject'"
            :id="id"
            @close="doCloseModal"
          />
          <app-request-close-form
            :type="type"
            :id="id"
            @close="doCloseModal"
            v-if="action == 'close'"
          />
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RequestAcceptForm from '@/views/payment/request/request-accept-form'
import RequestRejectForm from '@/views/payment/request/request-reject-form'
import RequestCloseForm from '@/views/payment/request/request-close-form.vue'

export default {
  name: 'app-request-form-modal',
  props: {
    id: {
      type: String
    },
    type: {
      type: String
    },
    action: {
      type: String
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    [RequestAcceptForm.name]: RequestAcceptForm,
    [RequestRejectForm.name]: RequestRejectForm,
    [RequestCloseForm.name]: RequestCloseForm
  },
  setup() {
    const hideModal = () => {
      cash('#request-form-modal').modal('hide')
    }
    return {
      hideModal
    }
  },
  computed: {
    ...mapGetters({
      record: 'request/form/record',
      findLoading: 'request/form/findLoading',
      saveLoading: 'request/form/saveLoading'
    })
  },
  methods: {
    ...mapActions({
      doNew: 'request/form/doNew',
      doFind: 'request/form/doFind'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    doCloseModal() {
      this.hideModal()
      this.$emit('reset-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-card {
  background: #ffffff !important;
  border-radius: 20px !important;
}
.card-header {
  color: #000000 !important;
  background: #d6d6d6 !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 34px !important;
  padding: 18px 32px !important;
}
// .card-form {
//   padding: 24px !important;
// }
</style>

<style lang="scss" scoped>
@media (max-width: 1439px) {
  .my-card {
    border-radius: calc(
      10px + (20 - 10) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }
  .card-header {
    font-size: calc(
      16px + (18 - 16) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    line-height: 34px !important;
    padding: calc(
      12px + (18 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    // calc(0px + (32 - 0) * ((100vw - 320px) / (1440 - 320)))
  }
  .card-form {
    padding: calc(16px + (24 - 16) * ((100vw - 320px) / (1440 - 320)))
      calc(12px + (24 - 12) * ((100vw - 320px) / (1440 - 320))) !important;
  }
}
</style>
