<template>
  <div>
    <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
      <div class="col-span-12">
        <label for="transactionId" class="form-label">{{
          i18n('request.modals.transactionId')
        }}</label>
        <input
          id="transactionId"
          v-model="transactionId"
          type="text"
          class="form-control w-full"
        />
      </div>
      <div class="col-span-12">
        <label for="filePdf" class="form-label">{{
          i18n('request.modals.uploadTransactionFile')
        }}</label>
        <Uploader
          id="filePdf"
          ref="uploader"
          type="application/pdf"
          :maxSize="5"
        ></Uploader>
      </div>
      <!-- <div class="col-span-12">
        <label for="notes" class="form-label">{{ i18n('request.modals.notes') }}</label>
        <textarea id="notes"
          v-model="notes"
          :rows="4"
          class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
          :placeholder="i18n('common.writeNotesHere')"
        ></textarea>
      </div> -->
    </div>

    <div class="modal-footer flex justify-center items-center gap-2">
      <AppButton
        id="submit-btn"
        type="button"
        class="btn bg-theme-36 text-white cursor-pointer"
        :class="!saveLoading ? 'w-24' : ''"
        :disabled="saveLoading"
        :loading="saveLoading"
        @click="doSubmit()"
      >
        <strong>{{ i18n('common.save') }}</strong>
        <template v-slot:loading>
          <span>{{ i18n('common.loading') }}</span>
          <LoadingIcon
            icon="three-dots"
            color="#FFFFFF"
            style="margin: 0 4px"
          />
        </template>
      </AppButton>

      <button
        type="button"
        data-dismiss="modal"
        class="btn bg-theme-31 text-black w-24"
        :disabled="saveLoading"
        @click="doCancel()"
      >
        {{ i18n('common.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default defineComponent({
  name: 'app-request-close-form',
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      transactionId: null,
      notes: null,
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      saveLoading: 'request/form/saveLoading'
    }),
    filesToSave() {
      return this.$refs.uploader.filesToSave
    }
  },
  methods: {
    ...mapActions({
      doClose: 'request/form/doClose'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (!this.transactionId) {
        this.errorMessage = this.isRTL
          ? 'رقم المعاملة مطلوب ، يرجى إضافة رقم المعاملة'
          : 'Transaction number is required, Please add transaction number'
        return false
      }

      if (this.filesToSave.length == 0) {
        this.errorMessage = this.isRTL
          ? 'ملف PDF مطلوب ، يرجى تحميل ملف PDF'
          : 'PDF file is required, Please upload the PDF file'
        return false
      }
      return true
    },
    doCancel() {
      this.doReset()
      this.$emit('close')
    },
    doReset() {
      this.transactionId = null
      this.notes = null
      this.$refs.uploader.clearDropzone()
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      await this.doClose({
        id: this.id,
        transactionId: this.transactionId,
        type: this.type,
        file: this.filesToSave[0]
      })
      this.doReset()
      this.$emit('close')
    }
  }
})
</script>

<style>
</style>
