<template>
  <div>
    <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
      <div class="col-span-12">
        <label for="dueDate" class="form-label">{{
          i18n('request.modals.dueDate')
        }}</label>
        <input
          id="dueDate"
          v-model="dueDate"
          type="date"
          class="form-control w-full"
        />
      </div>
      <div class="col-span-12">
        <label for="notes" class="form-label">{{
          i18n('request.modals.notes')
        }}</label>
        <textarea
          id="notes"
          v-model="notes"
          :rows="4"
          class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
          :placeholder="i18n('common.writeNotesHere')"
        ></textarea>
      </div>
    </div>

    <div class="modal-footer flex justify-center items-center gap-2">
      <AppButton
        id="submit-btn"
        type="button"
        class="btn bg-theme-36 text-white cursor-pointer"
        :class="!saveLoading ? 'w-24' : ''"
        :disabled="saveLoading"
        :loading="saveLoading"
        @click="doSubmit()"
      >
        <strong>{{ i18n('common.save') }}</strong>
        <template v-slot:loading>
          <span>{{ i18n('common.loading') }}</span>
          <LoadingIcon
            icon="three-dots"
            color="#FFFFFF"
            style="margin: 0 4px"
          />
        </template>
      </AppButton>

      <button
        type="button"
        data-dismiss="modal"
        class="btn bg-theme-31 text-black w-24"
        :disabled="saveLoading"
        @click="doCancel()"
      >
        {{ i18n('common.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  name: 'app-request-accept-form',
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      dueDate: null,
      notes: null,
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      saveLoading: 'request/form/saveLoading'
    })
  },
  methods: {
    ...mapActions({
      doAccept: 'request/form/doAccept'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (!this.dueDate) {
        this.errorMessage = 'Due Date is required, Please add due date'
        return false
      }
      return true
    },
    doCancel() {
      this.doReset()
      this.$emit('close')
    },
    doReset() {
      this.dueDate = null
      this.notes = null
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      await this.doAccept({
        id: this.id,
        type: this.type,
        dueDate: new Date(this.dueDate),
        notes: this.notes
      })
      this.doReset()
      this.$emit('close')
    }
  }
}
</script>

<style>
</style>
