<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('payments.title') }}
      </h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
        role="tablist"
      >
        <a
          id="requests-tab"
          data-toggle="tab"
          data-target="#requests"
          href="javascript:;"
          class="flex items-center gap-2 py-4 sm:mr-8 active"
          role="tab"
          aria-controls="requests"
          aria-selected="true"
          @click="tab = 'chef'"
        >
          <app-i18n code="payments.chefRequests"></app-i18n>
          <Tippy
            tag="div"
            class="bg-theme-24 text-white font-bold rounded-full px-1.5"
            style="border-radius: 50%"
            :content="isRTL ? 'طلبات جديدة' : 'New requests'"
            v-if="chefsRequestsBadge > 0"
          >
            {{ chefsRequestsBadge }}
          </Tippy>
        </a>
        <a
          id="requests-tab"
          data-toggle="tab"
          data-target="#requests"
          href="javascript:;"
          class="flex items-center gap-2 py-4 sm:mr-8"
          role="tab"
          aria-controls="requests"
          aria-selected="true"
          @click="tab = 'captain'"
        >
          <app-i18n code="payments.captainRequests"></app-i18n>
          <Tippy
            tag="div"
            class="bg-theme-24 text-white font-bold rounded-full px-1.5"
            style="border-radius: 50%"
            :content="isRTL ? 'طلبات جديدة' : 'New requests'"
            v-if="captainsRequestsBadge > 0"
          >
            {{ captainsRequestsBadge }}
          </Tippy>
        </a>
      </div>
    </div>
    <!-- END: Profile Info -->
    <div class="intro-y tab-content mt-5">
      <app-request-list-page :tab="tab" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RequestListPage from '@/views/payment/request/request-list-page.vue'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.payments')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.payments')
    }
  },
  components: {
    [RequestListPage.name]: RequestListPage
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      captainsRequestsBadge: 'realtime/captainsRequestsBadge',
      chefsRequestsBadge: 'realtime/chefsRequestsBadge'
    })
  },
  data() {
    return {
      tab: 'chef'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
