/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class RequestPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.takeAction = permissionChecker.match('takeRequestAction');
    this.print = permissionChecker.match('printRequestsTable');
    this.export = permissionChecker.match('exportRequestsTable');
  }
}
