<template>
  <div
    id="requests"
    class="tab-pane active"
    role="tabpanel"
    aria-labelledby="requests-tab"
  >
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->

    <div class="intro-y box grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-12 p-5">
        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
        >
          <app-action-toolbar
            @onPrint="doPrint"
            @onExportXlsx="onExportXlsx"
            @onExportAllXlsx="onExportAllXlsx"
            @onDownloadPdf="doDownloadPdf"
            :printPermission="hasPermissionToPrint"
            :exportPermission="hasPermissionToExport"
          ></app-action-toolbar>
        </div>
        <!-- BEGIN: HTML Table Data -->
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              id="tablePrint"
              ref="tabulator"
              class="table table-report sm:my-2"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap" id="ignore-1">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="
                      col.name === 'action'
                        ? 'ignore-2'
                        : col.name === 'description'
                        ? 'ignore-3'
                        : ''
                    "
                    class="text-center whitespace-nowrap"
                  >
                    <span>{{ i18n(col.label) }}</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="intro-x"
                  :class="{ removedRow: row.isRemoved }"
                >
                  <td class="w-40" id="ignore-4">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row[tab], 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <router-link
                      class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30 cursor-pointer"
                      :to="`/${row.userType}s/${row.ownerId}`"
                      target="_blank"
                    >
                      {{ presenterName(row[tab], 'fullName') }}
                    </router-link>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                    >
                      {{ presenterPrice(row.amount) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{
                        row.type
                          ? i18n(`request.enumerators.type.${row.type}`)
                          : 'ـــ'
                      }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row.bankAccount, 'bankName') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row.bankAccount, 'iban') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap flex items-center justify-center"
                      :class="{
                        'text-gray-500': row.status == 'pending',
                        'text-theme-24': row.status == 'rejected',
                        'text-theme-10 dark:text-theme-30':
                          row.status == 'confirmed'
                      }"
                    >
                      {{
                        row.status
                          ? i18n(`request.enumerators.status.${row.status}`)
                          : 'ـــ'
                      }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'transactionTimeStamp') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                    <div class="font-small text-xs whitespace-nowrap mt-1">
                      {{ presenterTime(row, 'createdAt') }}
                    </div>
                  </td>
                  <td
                    class="table-report__action w-56"
                    id="ignore-6"
                    v-if="hasPermissionToTakeAction"
                  >
                    <div
                      class="flex justify-center items-center gap-4"
                      v-if="row.status === 'pending'"
                    >
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#request-form-modal"
                        @click="doOpenModal(row, 'accept')"
                        class="text-theme-10 dark:text-theme-30 flex items-center"
                      >
                        <CheckCircleIcon
                          class="w-4 h-4"
                          :class="isRTL ? 'ml-2' : 'mr-2'"
                        />
                        {{ i18n('common.accept') }}
                      </a>
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#request-form-modal"
                        @click="doOpenModal(row, 'reject')"
                        class="text-theme-24 dark:text-theme-30 flex items-center"
                      >
                        <XCircleIcon
                          class="w-4 h-4"
                          :class="isRTL ? 'ml-2' : 'mr-2'"
                        />
                        {{ i18n('common.reject') }}
                      </a>
                    </div>
                    <div
                      class="flex justify-center items-center gap-4"
                      v-else-if="row.status === 'accepted'"
                    >
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#request-form-modal"
                        @click="doOpenModal(row, 'close')"
                        class="text-theme-1 dark:text-theme-30 flex items-center"
                      >
                        <CheckCircleIcon
                          class="w-4 h-4"
                          :class="isRTL ? 'ml-2' : 'mr-2'"
                        />
                        {{ i18n('common.close') }}
                      </a>
                    </div>
                    <a
                      :href="row.transactionUrl"
                      target="_blank"
                      class="flex justify-center items-center gap-4"
                      v-else-if="
                        row.status === 'confirmed' && row.transactionUrl
                      "
                    >
                      <Tippy
                        tag="div"
                        class="flex items-center gap-2"
                        :content="
                          isRTL
                            ? 'انقر هنا لفتح ملف pdf الخاص بالمعاملة'
                            : 'Click here to open the pdf file of transaction'
                        "
                      >
                        <link-2-icon
                          size="1.5x"
                          class="custom-class"
                        ></link-2-icon>
                      </Tippy>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.request')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :hasNext="hasNext"
            :hasPrev="hasPrev"
            :loading="loading"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
            @page-change="goToPage"
          ></Pagination>
        </div>
        <!-- END: HTML Table Data -->
      </div>
    </div>
    <app-request-form-modal
      :id="selectedId"
      :action="action"
      :type="tab"
      :dialogVisible="showModal"
      @reset-modal="doResetModal"
    ></app-request-form-modal>
    <AcceptModal :item="i18n('request.item')" @accept="doAccept(selectedId)" />
    <RejectModal
      :item="i18n('request.item')"
      @reject="doReject({ id: selectedId })"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RequestFormModal from '@/views/payment/request/request-form-modal.vue'
import AcceptModal from '@/components/modals/accept-modal.vue'
import RejectModal from '@/components/modals/reject-modal.vue'
import { RequestPermissions } from './request-permissions'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'

export default {
  name: 'app-request-list-page',
  mixins: [paginationMixin, presenterMixin, exportPrintMixin],
  props: {
    tab: {
      type: String
    }
  },
  components: {
    [RequestFormModal.name]: RequestFormModal,
    AcceptModal,
    RejectModal
  },
  data() {
    return {
      // data important for Mixins
      tableRef: null,
      exportTableName: 'requests',
      excludedCols: [5, 8, 11],
      ignoreElements: [
        'ignore-1',
        'ignore-2',
        'ignore-3',
        'ignore-4',
        'ignore-5',
        'ignore-6'
      ],
      // component Data
      selectedId: null,
      action: null,
      showModal: false,
      columns: [
        {
          name: 'code',
          field: 'code',
          label: 'common.code',
          align: 'center'
        },
        {
          name: 'userName',
          field: 'creator',
          label: 'request.fields.userName',
          align: 'center'
        },
        {
          name: 'amount',
          field: 'amount',
          label: 'request.fields.amount',
          align: 'center'
        },
        {
          name: 'type',
          field: 'type',
          label: 'request.fields.type',
          align: 'center'
        },
        {
          name: 'bankName',
          field: 'bankAccount',
          label: 'request.fields.bankName',
          align: 'center'
        },
        {
          name: 'iban',
          field: 'bankAccount',
          label: 'request.fields.iban',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'request.fields.status',
          align: 'center'
        },
        {
          name: 'dueDate',
          field: 'dueDate',
          label: 'request.fields.dueDate',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'request.fields.createdAt',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'request/list/rows',
      hasNext: 'request/list/hasNext',
      hasPrev: 'request/list/hasPrev',
      pagination: 'request/list/pagination',
      listLoading: 'request/list/loading',
      saveLoading: 'request/form/saveLoading',
      exportLoading: 'exportStore/loading'
    }),
    fetchOptions() {
      return { type: this.tab }
    },
    exportResourceName() {
      return `requests-${this.tab}s`
    },
    hasPermissionToTakeAction() {
      return new RequestPermissions(this.currentUser).takeAction
    },
    hasPermissionToPrint() {
      return new RequestPermissions(this.currentUser).print
    },
    hasPermissionToExport() {
      return new RequestPermissions(this.currentUser).export
    },
    loading() {
      return this.listLoading || this.saveLoading || this.exportLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    statusOptions() {
      return [
        {
          label: this.i18n('request.enumerators.status.pending'),
          value: 'pending'
        },
        {
          label: this.i18n('request.enumerators.status.accepted'),
          value: 'accepted'
        },
        {
          label: this.i18n('request.enumerators.status.rejected'),
          value: 'rejected'
        },
        {
          label: this.i18n('request.enumerators.status.confirmed'),
          value: 'confirmed'
        }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.fields.code'),
          value: 'code',
          type: 'text'
        },
        {
          label: this.i18n('chefs.filter.fullName'),
          value: 'fullName',
          type: 'text'
        },
        {
          label: this.i18n('request.fields.amount'),
          value: 'amount',
          type: 'number-range'
        },
        {
          label: this.i18n('request.fields.type'),
          value: 'type',
          type: 'text'
        },
        {
          label: this.i18n('request.fields.bankName'),
          value: 'bankAccount.bankName',
          type: 'text'
        },
        {
          label: this.i18n('request.fields.iban'),
          value: 'bankAccount.iban',
          type: 'text'
        },
        {
          label: this.i18n('request.fields.status'),
          value: 'status',
          type: 'list',
          options: this.statusOptions
        },
        {
          label: this.i18n('request.fields.dueDate'),
          value: 'transactionTimeStamp',
          type: 'date-range'
        },
        {
          label: this.i18n('request.fields.createdAt'),
          value: 'createdAt',
          type: 'date-range'
        }
      ]
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  watch: {
    requestsBadge() {
      this.doFetchFirstPage(false)
    },
    tab() {
      this.doFetchFirstPage()
    }
  },
  methods: {
    ...mapActions({
      doFetch: 'request/list/doFetch',
      doFetchNextPage: 'request/list/doFetchNextPage',
      doFetchPreviousPage: 'request/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'request/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'request/list/doChangePaginationPageSize',
      doAccept: 'request/form/doAccept',
      doReject: 'request/form/doReject',
      doExport: 'exportStore/doExport'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterName(row, fieldName) {
      if (row && !row[fieldName] && this.tab === 'chef') {
        return this.presenterLocalization(row.kitchen, 'kitchenName')
      }
      return this.presenter(row, fieldName)
    },
    doOpenModal(row, action) {
      this.selectedId = row.id
      this.action = action
      this.showModal = true
    },
    doResetModal() {
      this.selectedId = null
      this.action = null
      this.showModal = false
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
