export const filterMixin = {
  data() {
    return { filterInput: [] }
  },
  methods: {
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter) return
      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }
      await this.$router.push({
        query: {}
      })
      await this.doFetchFirstPage(orderBy, true)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.$router.push({
        query: {}
      })
      await this.doFetchFirstPage('createdAt', true)
    }
  }
}
