// import crypto from '@/shared/crypto'
import { filterMixin } from '@/mixins/filteration-mixin'

export const paginationMixin = {
  mixins: [filterMixin],
  data() {
    return {
      defaultPagination: {
        limit: 50,
        action: 'next',
        orderBy: 'createdAt',
        page: 1,
        sortType: 'desc'
      }
    }
  },
  methods: {
    urlToFilter(filterParams) {
      return filterParams.split('&').map(pair => {
        const [keyValue, value] = pair.split('=')
        const [key, operator] = keyValue.split('_')
        return { key, operator, value }
      })
    },
    async doFetchFirstPage(filterOrderBy, newFilter = false) {
      // get route query parametar
      const routeQuery = this.$route.query
      const { limit, action, orderBy, sortType, page, filter } =
        routeQuery && Object.keys(routeQuery).length > 0
          ? routeQuery
          : this.defaultPagination
      const currentPage = page || 1
      // construct pagination
      const pagination = {
        action,
        sortType,
        page: +page,
        limit: +limit,
        orderBy: filterOrderBy || orderBy
      }

      const filterInp =
        !newFilter && filter ? this.urlToFilter(filter) : this.filterInput

      await this.doChangePaginationCurrentPage(+currentPage)
      await this.doFetch({
        ...this.fetchOptions,
        filter: filterInp,
        pagination
      })
      this.filterInput = filterInp
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    async goToPage(pageNum) {
      const routeQuery = this.$route.query
      const { limit, action, orderBy, sortType } =
        routeQuery && Object.keys(routeQuery).length > 0
          ? routeQuery
          : this.defaultPagination
      const pagination = {
        action,
        sortType,
        orderBy,
        page: +pageNum,
        limit: +limit
      }
      await this.doChangePaginationCurrentPage(+pageNum)
      await this.doFetch({
        ...this.fetchOptions,
        filter: this.filterInput,
        pagination
      })
    }
  }
}
